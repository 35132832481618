import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState, } from 'react';
import { NETWORKS } from '@/constants';
import { Token } from '@pheasant-network/pheasant-sdk';
const PhaseContext = createContext({
    // 0: nothing started, 1: approve, 2: swap
    phase: 0,
    setPhase: () => { },
});
export const usePhaseContext = () => {
    return useContext(PhaseContext);
};
const IsUpwardTradeContext = createContext({
    isUpwardTrade: false,
    setIsUpwardTrade: () => { },
});
export const useIsUpwardTradeContext = () => {
    return useContext(IsUpwardTradeContext);
};
const SelectedNetworkContext = createContext({
    selectedNetwork: null,
    setSelectedNetwork: () => { },
    selectedFromNetwork: null,
    setSelectedFromNetwork: () => { },
    selectedToNetwork: null,
    setSelectedToNetwork: () => { },
});
export const useSelectedNetworkContext = () => {
    return useContext(SelectedNetworkContext);
};
const ModalContext = createContext({
    isProcessingModalOpen: false,
    setIsProcessingModalOpen: () => { },
    isNoticeModalOpen: false,
    setIsNoticeModalOpen: () => { },
    isTimedOutModalOpen: false,
    setIsTimedOutModalOpen: () => { },
    isActiveNotification: false,
    setIsActiveNotification: () => { },
    isUnknownErrorModalOpen: false,
    setIsUnknownErrorModalOpen: () => { },
});
export const useModalContext = () => {
    return useContext(ModalContext);
};
const IsDarkModeContext = createContext({
    isDarkMode: false,
    setIsDarkMode: () => { },
});
export const useIsDarkModeContext = () => {
    return useContext(IsDarkModeContext);
};
const IsFinishedTradeContext = createContext({
    isFinishedTrade: false,
    setIsFinishedTrade: () => { },
});
export const useIsFinishedTrade = () => {
    return useContext(IsFinishedTradeContext);
};
const IsVisibleNewsBannerContext = createContext({
    isVisibleNewsBanner: false,
    setIsVisibleNewsBanner: () => { },
});
export const useIsVisibleNewsBanner = () => {
    return useContext(IsVisibleNewsBannerContext);
};
const WalletImagePathContext = createContext({
    walletImagePath: "",
    setWalletImagePath: () => { },
});
export const useWalletImagePath = () => {
    return useContext(WalletImagePathContext);
};
const CurrentTxInfoContext = createContext({
    currentTxInfo: { address: '', status: 0, timestamp: 0, destCode: 0, chainName: '', amount: '', fee: 0, tokenTypeIndex: 0 },
    setCurrentTxInfo: () => { },
});
export const useCurrentTxInfo = () => {
    return useContext(CurrentTxInfoContext);
};
const TradeTokenTypeIndexContext = createContext({
    tradeTokenTypeIndex: Token.ETH,
    setTradeTokenTypeIndex: () => { },
});
export const useTradeTokenTypeIndexContext = () => {
    return useContext(TradeTokenTypeIndexContext);
};
export const ContextProvider = ({ children }) => {
    const [phase, setPhase] = useState(0);
    const [selectedNetwork, setSelectedNetwork] = useState(NETWORKS[0]);
    const [selectedFromNetwork, setSelectedFromNetwork] = useState(NETWORKS[0]);
    const [selectedToNetwork, setSelectedToNetwork] = useState(NETWORKS[0]);
    const [isUpwardTrade, setIsUpwardTrade] = useState(true);
    const [isProcessingModalOpen, setIsProcessingModalOpen] = useState(false);
    const [isNoticeModalOpen, setIsNoticeModalOpen] = useState(false);
    const [isTimedOutModalOpen, setIsTimedOutModalOpen] = useState(false);
    const [isActiveNotification, setIsActiveNotification] = useState(false);
    const [isUnknownErrorModalOpen, setIsUnknownErrorModalOpen] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(true);
    const [isFinishedTrade, setIsFinishedTrade] = useState(false);
    const [isVisibleNewsBanner, setIsVisibleNewsBanner] = useState(false);
    const [walletImagePath, setWalletImagePath] = useState("");
    const [currentTxInfo, setCurrentTxInfo] = useState({
        address: '',
        status: 0,
        timestamp: 0,
        destCode: 0,
        chainName: '',
        amount: '',
        fee: 0,
        tokenTypeIndex: 0,
    });
    const [tradeTokenTypeIndex, setTradeTokenTypeIndex] = useState(Token.ETH);
    const tradeTokenTypeIndexContextValue = {
        tradeTokenTypeIndex,
        setTradeTokenTypeIndex,
    };
    const currentTxInfoContextValue = {
        currentTxInfo,
        setCurrentTxInfo,
    };
    const walletImagePathContextValue = {
        walletImagePath,
        setWalletImagePath,
    };
    const isVisibleNewsBannerContextValue = {
        isVisibleNewsBanner,
        setIsVisibleNewsBanner,
    };
    const isFinishedTradeContextValue = {
        isFinishedTrade,
        setIsFinishedTrade,
    };
    const isDarkModeContextValue = {
        isDarkMode,
        setIsDarkMode,
    };
    const isUpwardTradeContextValue = {
        isUpwardTrade,
        setIsUpwardTrade,
    };
    const phaseContextValue = {
        phase,
        setPhase,
    };
    const selectedNetworkContextValue = {
        selectedNetwork,
        setSelectedNetwork,
        selectedFromNetwork,
        setSelectedFromNetwork,
        selectedToNetwork,
        setSelectedToNetwork,
    };
    const modalContextValue = {
        isProcessingModalOpen,
        setIsProcessingModalOpen,
        isNoticeModalOpen,
        setIsNoticeModalOpen,
        isTimedOutModalOpen,
        setIsTimedOutModalOpen,
        isActiveNotification,
        setIsActiveNotification,
        isUnknownErrorModalOpen,
        setIsUnknownErrorModalOpen,
    };
    useEffect(() => {
        const darkMode = localStorage.getItem('darkMode')
            ? localStorage.getItem('darkMode')
            : 'true';
        setIsDarkMode(darkMode === 'true' ? true : false);
    }, []);
    useEffect(() => {
        if (isDarkMode) {
            // tailwind
            document.documentElement.classList.add('dark');
            // css
            document.documentElement.setAttribute('data-theme', 'dark');
        }
        else {
            document.documentElement.classList.remove('dark');
            document.documentElement.removeAttribute('data-theme');
        }
    }, [isDarkMode]);
    return (_jsx(IsDarkModeContext.Provider, { value: isDarkModeContextValue, children: _jsx(WalletImagePathContext.Provider, { value: walletImagePathContextValue, children: _jsx(ModalContext.Provider, { value: modalContextValue, children: _jsx(SelectedNetworkContext.Provider, { value: selectedNetworkContextValue, children: _jsx(TradeTokenTypeIndexContext.Provider, { value: tradeTokenTypeIndexContextValue, children: _jsx(IsUpwardTradeContext.Provider, { value: isUpwardTradeContextValue, children: _jsx(CurrentTxInfoContext.Provider, { value: currentTxInfoContextValue, children: _jsx(PhaseContext.Provider, { value: phaseContextValue, children: _jsx(IsFinishedTradeContext.Provider, { value: isFinishedTradeContextValue, children: _jsx(IsVisibleNewsBannerContext.Provider, { value: isVisibleNewsBannerContextValue, children: children }) }) }) }) }) }) }) }) }) }));
};
