import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import '@/domain/main/frame.css';
import Tippy from '@tippyjs/react';
import { fetchBalance, fetchFeeData } from '@wagmi/core';
import { memo, useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';
import { useAccount, useSwitchNetwork } from 'wagmi';
import eyeIcon from '@/assets/logo/eye/googly-1.svg';
import addressIcon from '@/assets/icon/address.svg';
import arrow from '@/assets/icon/arrow-double.svg';
import arrowWhite from '@/assets/icon/arrow-double-white.svg';
import feeOld from '@/assets/icon/fee.svg';
import triangle from '@/assets/icon/triangle.svg';
import triangleWhite from '@/assets/icon/triangle-white.svg';
import lineTriangle from '@/assets/icon/line-triangle.svg';
import lineTriangleWhite from '@/assets/icon/line-triangle-white.svg';
import jito from '@/assets/logo/eye/jito.svg';
import zzz from '@/assets/logo/eye/zzz.svg';
import Loading from '@/components/Loading';
import MessageMobile from '@/components/MessageMobile';
import SimpleModal from '@/components/SimpleModal';
import Teleport from '@/components/Teleport';
import { constants, NETWORKS } from '@/constants';
import { useIsDarkModeContext, useIsUpwardTradeContext, useSelectedNetworkContext, useTradeTokenTypeIndexContext, } from '@/context';
import DetailRowWithIcon from '@/domain/main/DetailRowWithIcon';
import Footer from '@/domain/main/Footer';
import Frame from '@/domain/main/Frame';
import Header from '@/domain/main/Header';
import NetworkModalContent from '@/domain/main/NetworkModalContent';
import TextRow from '@/domain/main/TextRowWithReader';
import { formatNumberWithFourDecimalPlaces, getImage, parseStringToNumber, } from '@/utils';
import { supportChains, supportUsdcChains } from '@/index';
import { PheasantSDK, Token, } from '@pheasant-network/pheasant-sdk';
import { useNetworkManagerContext } from '@/context/NetworkManagerContext';
import UsdcBridgeInfo from './UsdcBridgeInfo';
const StyledTippy = styled(Tippy) `
  background-color: rgb(244 112 104);
  .tippy-content {
    background-color: transparent !important;
  }
`;
const USE_LOCAL_CONTRACT_DATA = false;
const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
};
const MAINNET_CHAIN_ID = 1;
let maxThreshold = 0;
let minThreshold = 0;
const MainCard = memo(({ isNoticeModalOpen, getEstimatedReceived, isFeeCalculated, totalFee, setAmountStr, estimatedReceived, selectFromNetwork, selectedToNetworkHandler, isApproved, isFirstFeeCalculated, setIsFirstFeeCalculated, txhash, prepare, executeTradeTemporarily, executeTrade, approve, isInitialized, recipient, setRecipient, isTemporarySetting, calcTimerID, isDeposited, isProcessing, isProcessingModalOpen, isValidated, setIsValidated, validateErrorMsg, thresholds, usdcThresholds, isCctpProcessingModalOpen, isCctp, resetInput, }) => {
    const { isDarkMode } = useIsDarkModeContext();
    const { tradeTokenTypeIndex } = useTradeTokenTypeIndexContext();
    const [characterEyeUrl, setCharacterEyeUrl] = useState(eyeIcon);
    const [isOpen, setIsOpen] = useState(false);
    const { isLoading: switching } = useSwitchNetwork();
    const { isUpwardTrade } = useIsUpwardTradeContext();
    const [isOpenNetworkModal, setIsOpenNetworkModal] = useState(false);
    const { selectedFromNetwork, setSelectedFromNetwork, selectedToNetwork, setSelectedToNetwork, } = useSelectedNetworkContext();
    const { address, isConnected, isDisconnected } = useAccount();
    const [networkFlag, setNetworkFlag] = useState(null);
    const [isFetchingBalances, setIsFetchingBalances] = useState(false);
    const [balances, setBalances] = useState({});
    const [gasBalances, setGasBalances] = useState({});
    const [fourDecimalAmount, setAmount] = useState(null);
    const [placeholder, setPlaceholder] = useState('0');
    const [fromCurrency, setFromCurrency] = useState('ETH');
    const [toCurrency, setToCurrency] = useState('ETH');
    const [unit, setUnit] = useState('ETH');
    const [isInvalidAmount, setIsInvalidAmount] = useState(false);
    const [isInvalidMainnetAmount, setIsInvalidMainnetAmount] = useState(false);
    const [isFocusOut, setIsFocusOut] = useState(false);
    const [isBridgeInfoOpen, setIsBridgeInfoOpen] = useState(false);
    const [isBridgeInfoOpenInLocalStorage, setIsBridgeInfoOpenInLocalStorage] = useState(true);
    const isMainnet = process.env.REACT_APP_IS_MAINNET === 'true';
    // custom hook
    const networkManager = useNetworkManagerContext();
    // set threshold
    useEffect(() => {
        if (selectedFromNetwork.id == 0 ||
            selectedToNetwork.id == 0 ||
            !isConnected) {
            return;
        }
        const threshold = getThresholdInfo();
        if (threshold) {
            if (selectedFromNetwork.isCctp && selectedToNetwork.isCctp) {
                // CCTP
                maxThreshold = constants.CCTP_THRESHOLD;
                minThreshold = threshold.minimumAmount;
            }
            else {
                maxThreshold = threshold.thresholdAmount;
                minThreshold = threshold.minimumAmount;
            }
        }
        else {
            maxThreshold = 0;
            minThreshold = 0;
        }
    }, [
        selectedFromNetwork,
        selectedToNetwork,
        thresholds,
        tradeTokenTypeIndex,
    ]);
    const getThresholdInfo = () => {
        let selectedNetworkId = 0;
        if (networkManager
            .getL1NetworkChainIds()
            .includes(selectedFromNetwork.networkID)) {
            selectedNetworkId = selectedToNetwork.id;
        }
        else if (networkManager
            .getL1NetworkChainIds()
            .includes(selectedToNetwork.networkID)) {
            selectedNetworkId = selectedFromNetwork.id;
        }
        else {
            selectedNetworkId = selectedFromNetwork.id;
        }
        const threshold = tradeTokenTypeIndex === Token.ETH
            ? thresholds[selectedNetworkId]
            : usdcThresholds[selectedNetworkId];
        console.log('threshold: ', threshold);
        return threshold;
    };
    const randomCharacterImage = () => {
        // ページ上部のPheasantくんの表情をランダムで変更
        const num = Math.floor(Math.random() * 8) + 1;
        const targetUrl = 'googly-' + String(num) + '.svg';
        if (targetUrl != null) {
            const image = require('@/assets/logo/eye/' + targetUrl);
            setCharacterEyeUrl(image.default);
        }
    };
    const getTempMaxBridgeableAmount = () => {
        return parseStringToNumber(balances[selectedFromNetwork.networkID]?.formatted) >= maxThreshold
            ? formatNumberWithFourDecimalPlaces(String(maxThreshold))
            : formatNumberWithFourDecimalPlaces(balances[selectedFromNetwork.networkID]?.formatted);
    };
    const getMaxEnoughBridgeableAmount = async () => {
        const balance = await getMaxValueWithBuffer();
        return parseStringToNumber(balance) >= maxThreshold
            ? formatNumberWithFourDecimalPlaces(String(maxThreshold))
            : formatNumberWithFourDecimalPlaces(balance);
    };
    const getMaxValueWithBuffer = async () => {
        const balance = balances[selectedFromNetwork.networkID];
        if (balance === undefined)
            return '0';
        if (tradeTokenTypeIndex === Token.USDC)
            return formatNumberWithFourDecimalPlaces(balance.formatted);
        const feeData = await fetchFeeData({
            chainId: selectedFromNetwork.networkID,
        });
        if (feeData.gasPrice === null)
            return '0';
        const isNonNative = networkManager
            .getNonNativeNetworkChainIds()
            .includes(selectedFromNetwork.networkID);
        let buffer;
        const gasPrice = BigInt(feeData.gasPrice);
        const BUFFER_MULTIPLIER = 1;
        let resultEth;
        if (isNonNative) {
            const gasBalance = gasBalances[selectedFromNetwork.networkID];
            if (gasBalance === undefined)
                return '0';
            const approveGas = BigInt(constants.APPROVE_GAS);
            const newTradeWethGas = BigInt(constants.NEWTRADE_WETH_GAS);
            buffer =
                (approveGas * gasPrice + newTradeWethGas * gasPrice) *
                    BigInt(BUFFER_MULTIPLIER);
            const resultBigInt = gasBalance.value - buffer;
            resultEth = Number(resultBigInt) < 0 ? 0 : Number(balance.value) / 1e18;
        }
        else if (networkManager
            .getL1NetworkChainIds()
            .includes(selectedFromNetwork.networkID)) {
            const depositGas = BigInt(constants.NEWTRADE_ETH_GAS);
            buffer = depositGas * gasPrice * BigInt(BUFFER_MULTIPLIER);
            const resultBigInt = balance.value - buffer;
            resultEth = Number(resultBigInt) < 0 ? 0 : Number(resultBigInt) / 1e18;
        }
        else {
            const newTradeEthGas = BigInt(constants.NEWTRADE_ETH_GAS);
            buffer = newTradeEthGas * gasPrice * BigInt(BUFFER_MULTIPLIER);
            const resultBigInt = balance.value - buffer;
            resultEth = Number(resultBigInt) < 0 ? 0 : Number(resultBigInt) / 1e18;
        }
        return formatNumberWithFourDecimalPlaces(String(resultEth));
    };
    const maxClick = async () => {
        clearInterval(calcTimerID);
        setIsFirstFeeCalculated(false);
        if (selectedFromNetwork.id === 0)
            return;
        const maxValueString = await getMaxEnoughBridgeableAmount();
        const maxValue = Number(maxValueString);
        setAmount(maxValueString);
        const isInvalidAmount = maxValue === 0 ||
            maxValue > maxThreshold ||
            maxValue < minThreshold ||
            (selectedFromNetwork.id !== 0 &&
                maxValue >
                    Number(formatNumberWithFourDecimalPlaces(balances[selectedFromNetwork.networkID].formatted)));
        if (isInvalidAmount) {
            setIsInvalidAmount(isInvalidAmount);
            return;
        }
        setAmountStr(maxValueString);
        setIsFocusOut(true);
        setIsBridgeInfoOpen(true);
    };
    const toggleClick = () => {
        clearInterval(calcTimerID);
        setIsFirstFeeCalculated(false);
        selectFromNetwork(selectedToNetwork);
        setSelectedFromNetwork(selectedToNetwork);
        setSelectedToNetwork(selectedFromNetwork);
        setIsFocusOut(true);
    };
    const fetchBalances = async () => {
        setIsFetchingBalances(true);
        const updatedBalances = {};
        const updatedGasBalances = {};
        try {
            const chains = tradeTokenTypeIndex === Token.USDC ? supportUsdcChains : supportChains;
            await Promise.any(chains.map(async (chain) => {
                let token;
                const nonNativeNetworksIds = networkManager.getNonNativeNetworkChainIds();
                if (tradeTokenTypeIndex === Token.USDC) {
                    const network = networkManager.getNetworkInfoByChainId(chain.id);
                    const usdcAddress = networkManager.getTokenContractAddress(network?.chainNameLower, process.env.REACT_APP_ENV, 'USDC');
                    token = usdcAddress;
                }
                else if (nonNativeNetworksIds.includes(chain.id)) {
                    const wethAddress = await PheasantSDK.getLatestContractAddress(chain.id, 'wethAddress', USE_LOCAL_CONTRACT_DATA, networkManager.networkProvider);
                    token = wethAddress;
                    // Native Token
                    const gasBalance = await fetchBalance({
                        address: address,
                        chainId: chain.id,
                    });
                    updatedGasBalances[chain.id] = gasBalance;
                }
                const balance = await fetchBalance({
                    address: address,
                    chainId: chain.id,
                    token: token,
                });
                updatedBalances[chain.id] = balance;
            }));
            if (!isMainnet) {
                const balance = await fetchBalance({
                    address: address,
                    chainId: MAINNET_CHAIN_ID,
                    token: undefined,
                });
                updatedBalances[MAINNET_CHAIN_ID] = balance;
            }
            setBalances(updatedBalances);
            setGasBalances(updatedGasBalances);
        }
        catch (error) {
            console.error('Error fetching balances:', error);
        }
        finally {
            setIsFetchingBalances(false);
        }
    };
    useEffect(() => {
        if (isConnected && !isApproved) {
            setPlaceholder('0');
            setAmount(null);
            setIsInvalidAmount(false);
            fetchBalances();
        }
        else if (isDisconnected) {
            setBalances({});
        }
    }, [isConnected, address, isNoticeModalOpen === true, tradeTokenTypeIndex]);
    const getErrorMessage = () => {
        if (!isConnected)
            return constants.NOT_CONNECTED_ERROR;
        else if (!isMainnet &&
            balances[MAINNET_CHAIN_ID] &&
            Number(formatNumberWithFourDecimalPlaces(balances[MAINNET_CHAIN_ID].formatted)) < 0.001)
            return constants.MINIMUM_L1_BALANCE_ERROR;
        else if (Number(fourDecimalAmount) <= 0)
            return constants.ZERO_INPUT_ERROR;
        else if (Number(fourDecimalAmount) > maxThreshold) {
            // TODO
            return `No more than ${formatNumberWithFourDecimalPlaces(String(maxThreshold))} ${fromCurrency} allowed.`;
        }
        else if (Number(fourDecimalAmount) < minThreshold) {
            return `More than ${formatNumberWithFourDecimalPlaces(String(minThreshold))} ${fromCurrency} needed.`;
        }
        else if (selectedFromNetwork.id !== 0 &&
            Number(fourDecimalAmount) >
                Number(formatNumberWithFourDecimalPlaces(balances[selectedFromNetwork.networkID].formatted))) {
            return constants.INSUFFICIENT_ERROR;
        }
        else
            return '';
    };
    useEffect(() => {
        if (balances &&
            selectedFromNetwork &&
            selectedFromNetwork.id !== 0 &&
            selectedToNetwork &&
            selectedToNetwork.id !== 0 &&
            balances[selectedFromNetwork.networkID]) {
            const maxValueString = getTempMaxBridgeableAmount();
            setPlaceholder(maxValueString === '0'
                ? '0'
                : String(minThreshold) + '~' + maxValueString);
        }
    }, [
        balances,
        formatNumberWithFourDecimalPlaces,
        selectedFromNetwork,
        minThreshold,
        maxThreshold,
        tradeTokenTypeIndex,
    ]);
    const validateInput = (inputStr) => {
        const pattern = /^[0-9]+(\.[0-9]*)?$/;
        return pattern.test(inputStr);
    };
    const handleAmountChange = (e) => {
        setIsFocusOut(false);
        clearInterval(calcTimerID);
        setIsFirstFeeCalculated(false);
        setIsValidated(false);
        if (!validateInput(e.target.value))
            return;
        setAmount(e.target.value);
        setAmountStr(e.target.value);
        setIsBridgeInfoOpen(true);
    };
    // validation or get estimated received
    useEffect(() => {
        if ((networkFlag !== 'to' &&
            !fourDecimalAmount &&
            selectedFromNetwork?.id === 0) ||
            !isFocusOut)
            return;
        let isNotSelectedNetwork = false;
        if (fourDecimalAmount) {
            isNotSelectedNetwork =
                selectedFromNetwork.id === 0 ||
                    selectedToNetwork.id === 0 ||
                    selectedFromNetwork.id === selectedToNetwork.id;
        }
        const mainnetBalance = balances[MAINNET_CHAIN_ID]
            ? Number(formatNumberWithFourDecimalPlaces(balances[MAINNET_CHAIN_ID].formatted))
            : 0;
        const selectedNetworkBalance = balances[selectedFromNetwork.networkID]
            ? Number(formatNumberWithFourDecimalPlaces(balances[selectedFromNetwork.networkID].formatted))
            : 0;
        const isInvalidAmount = fourDecimalAmount !== null &&
            selectedFromNetwork.id !== 0 &&
            selectedToNetwork.id !== 0 &&
            (Number(fourDecimalAmount) === 0 ||
                Number(fourDecimalAmount) > maxThreshold ||
                Number(fourDecimalAmount) < minThreshold ||
                Number(fourDecimalAmount) > selectedNetworkBalance);
        setIsInvalidAmount(isInvalidAmount);
        const isInvalidMainnetAmount = !isMainnet && mainnetBalance < 0.001;
        setIsInvalidMainnetAmount(isInvalidMainnetAmount);
        if (!isValidated &&
            !isInvalidAmount &&
            !isInvalidMainnetAmount &&
            !isNotSelectedNetwork &&
            !isOpenNetworkModal) {
            getEstimatedReceived();
            setIsFocusOut(false);
        }
    }, [
        fourDecimalAmount,
        selectedFromNetwork,
        selectedToNetwork,
        isOpenNetworkModal,
        isFocusOut,
        tradeTokenTypeIndex,
    ]);
    // set from/to currencies name
    useEffect(() => {
        if (tradeTokenTypeIndex === Token.ETH &&
            networkManager
                .getNonNativeNetworkChainIds()
                .includes(selectedFromNetwork?.networkID)) {
            setFromCurrency('WETH');
            setUnit('WETH');
        }
        else if (tradeTokenTypeIndex === Token.USDC) {
            setFromCurrency('USDC');
            setUnit('USDC');
        }
        else {
            setFromCurrency('ETH');
            setUnit('ETH');
        }
        if (tradeTokenTypeIndex === Token.ETH &&
            networkManager
                .getNonNativeNetworkChainIds()
                .includes(selectedToNetwork?.networkID)) {
            setToCurrency('WETH');
        }
        else if (tradeTokenTypeIndex === Token.USDC) {
            setToCurrency('USDC');
        }
        else {
            setToCurrency('ETH');
        }
    }, [selectedFromNetwork, selectedToNetwork, tradeTokenTypeIndex]);
    // reset when disconnected or token changed
    useEffect(() => {
        setPlaceholder('0');
        setSelectedFromNetwork(NETWORKS[0]);
        setSelectedToNetwork(NETWORKS[0]);
        setIsBridgeInfoOpen(false);
        resetInput();
    }, [isDisconnected, tradeTokenTypeIndex]);
    useEffect(() => {
        setIsValidated(false);
    }, [selectedFromNetwork, selectedToNetwork]);
    useEffect(() => {
        randomCharacterImage();
    }, [isNoticeModalOpen, isDarkMode]);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) {
                setIsOpenNetworkModal(false);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    useEffect(() => {
        const storedValue = localStorage.getItem('dontShowBridgeInfo');
        if (storedValue === 'true') {
            setIsBridgeInfoOpenInLocalStorage(false);
        }
    }, [isBridgeInfoOpen]);
    return (_jsxs("main", { className: "wrapper z-0 mx-auto font-medium relative w-main pt-[.625rem] md:max-w-full md:w-full md:px-4 mt-10 md:mt-1", children: [_jsx("img", { src: characterEyeUrl, alt: "Pheasant Network", width: "40", height: "20", className: "top-[.625rem] absolute h-5 z-10 left-1/2 -translate-x-1/2 -translate-y-1/2 md:hidden" }), _jsxs("div", { id: "container", className: "container relative z-0 overflow-y-scroll flex flex-col border border-dark dark:border-custom-black bg-ground dark:bg-custom-light dark:md:bg-custom-dark rounded-2xl px-5 pt-5 pb-6 md:border-none md:px-1 md:pt-1 md:pb-6", children: [_jsx("div", { className: "w-full flex justify-center relative mb-5 z-20 md:mb-0", children: _jsx(Header, { isApproved: isApproved }) }), _jsxs("div", { className: "w-full bg-ground dark:bg-custom-light dark:md:bg-custom-dark z-10 md:mt-4 md:px-0 md:rounded-2xl", children: [_jsx(Frame, { className: "frame-top z-40 dark:border-custom-black dark:md:bg-custom-light", title: "From", header: _jsxs("div", { className: "flex flex-row justify-end mb-2 items-center", "aria-label": "balance", children: [_jsxs("p", { className: "from-balance mr-2 font-normal", children: ["Balance :", ' ', isFetchingBalances ? (_jsx(Loading, {})) : selectedFromNetwork &&
                                                    balances[selectedFromNetwork.networkID] ? (formatNumberWithFourDecimalPlaces(balances[selectedFromNetwork.networkID]?.formatted)) : ('0')] }), _jsxs("div", { className: `mobile-from-balance mr-2 font-normal hidden ${selectedFromNetwork &&
                                                balances[selectedFromNetwork.networkID] &&
                                                'flex-col'}`, children: ["Balance :", ' ', isFetchingBalances && isConnected ? (_jsx("div", { className: "pl-1", children: _jsx(Loading, {}) })) : selectedFromNetwork &&
                                                    balances[selectedFromNetwork.networkID] ? (_jsx("div", { children: formatNumberWithFourDecimalPlaces(balances[selectedFromNetwork.networkID]?.formatted) })) : ('0')] }), _jsx("button", { onClick: async () => {
                                                try {
                                                    await maxClick();
                                                }
                                                catch (error) {
                                                    console.log(error);
                                                }
                                            }, className: "relative bg-primary hover:bg-primary-dark rounded-lg py-1 px-4 ml-2 text-sm items-center transition font-bold input-items dark:text-custom-black cursor-pointer dark:disabled:bg-custom-lighter disabled:bg-gray-300 disabled:text-gray-500 disabled:hover:bg-gray-300 disabled:pointer-events-none", disabled: selectedFromNetwork?.id === 0 ||
                                                selectedToNetwork?.id === 0 ||
                                                isApproved ||
                                                isDeposited, "aria-label": "Max amount", "aria-disabled": selectedFromNetwork?.id === 0 ||
                                                selectedToNetwork?.id === 0 ||
                                                isApproved ||
                                                isDeposited, children: _jsx("span", { children: "MAX" }) })] }), children: _jsxs("div", { className: "flex flex-col items-center space-y-6 w-full", children: [_jsxs("div", { className: "flex w-full", children: [_jsx("button", { className: "flex items-center justify-between h-12 w-56 border border-black dark:border-custom-black rounded-full pr-1 pl-2 dark:bg-custom-dark", onClick: () => {
                                                        clearInterval(calcTimerID);
                                                        setIsFirstFeeCalculated(false);
                                                        setNetworkFlag('from');
                                                        setIsOpenNetworkModal(true);
                                                    }, disabled: isApproved ||
                                                        isProcessing ||
                                                        (!isInitialized && isConnected), "aria-label": "Select a from network", "aria-disabled": isApproved ||
                                                        isProcessing ||
                                                        (!isInitialized && isConnected), children: !isInitialized && isConnected ? (_jsx("div", { className: "w-full flex justify-center items-center", children: _jsx(Loading, {}) })) : (_jsxs(_Fragment, { children: [_jsxs("div", { children: [selectedFromNetwork?.img && (_jsx("img", { className: `h-6 inline min-w-6 w-auto ${switching ? 'blinking' : ''}`, src: getImage(selectedFromNetwork.img), alt: selectedFromNetwork.label, style: {
                                                                            width: `${selectedFromNetwork.width}rem`,
                                                                            height: `${selectedFromNetwork.height}rem`,
                                                                        } })), _jsx("span", { className: `ml-2 ${switching ? 'blinking' : ''}`, children: selectedFromNetwork?.label }), selectedToNetwork.id !== 0 &&
                                                                        isValidated &&
                                                                        validateErrorMsg ===
                                                                            constants.INVALID_NETWORK_SELECTION_ERROR && (_jsx(StyledTippy, { placement: "top", animation: "fade", visible: true, arrow: true, content: _jsx("div", { className: "tooltip", children: _jsx("div", { children: constants.NOT_SELECT_NETWORK }) }), offset: [0, 20], zIndex: 30, children: _jsx("span", { className: "error-indicator" }) }))] }), _jsx("div", { className: "arrow", children: _jsx("img", { src: isDarkMode ? triangleWhite : triangle, style: {
                                                                        width: '0.75rem',
                                                                        height: '0.5625rem',
                                                                    }, alt: "Open Select" }) })] })) }), _jsx("input", { className: `focus-outline input w-40 input-item ${isInvalidAmount &&
                                                        getErrorMessage() !== '' &&
                                                        'text-error border border-dashed border-error focus:outline-0'}
              `, placeholder: placeholder, name: "amount", id: "amount", type: "text", value: fourDecimalAmount === null ? '' : fourDecimalAmount, onChange: handleAmountChange, onBlur: () => {
                                                        setIsFocusOut(true);
                                                    }, onKeyDown: (e) => {
                                                        if (e.key === 'Backspace' &&
                                                            /^\d+$/.test(e.currentTarget.value)) {
                                                            setAmount(null);
                                                        }
                                                    }, inputMode: "decimal", pattern: "[0-9]*[.,]?[0-9]+", readOnly: isApproved || isDeposited, "aria-label": "Enter from amount", "aria-required": "true", "aria-invalid": isInvalidAmount || isInvalidMainnetAmount, autoComplete: "off", autoCorrect: "off" }), (isInvalidAmount || isInvalidMainnetAmount) &&
                                                    getErrorMessage() !== '' && (_jsx(StyledTippy, { placement: "bottom", animation: "fade", visible: true, arrow: true, content: _jsx("div", { className: "tooltip", children: _jsx("div", { children: getErrorMessage() }) }), offset: [-50, 20], zIndex: 30, children: _jsx("span", { className: "error-indicator" }) })), isValidated &&
                                                    validateErrorMsg !==
                                                        constants.INVALID_NETWORK_SELECTION_ERROR && (_jsx(StyledTippy, { placement: "bottom", animation: "fade", visible: true, arrow: true, content: _jsx("div", { className: "tooltip", children: _jsx("div", { children: validateErrorMsg }) }), offset: [-50, 20], zIndex: 30, children: _jsx("span", { className: "error-indicator" }) })), _jsx("span", { className: "py-1 pl-3 font-bold text-sm leading-[1.75]", children: fromCurrency })] }), isBridgeInfoOpen &&
                                            isBridgeInfoOpenInLocalStorage &&
                                            tradeTokenTypeIndex === Token.USDC && (_jsx("div", { className: "w-full flex items-center justify-center", children: _jsx(UsdcBridgeInfo, { isOpen: isBridgeInfoOpen &&
                                                    isBridgeInfoOpenInLocalStorage &&
                                                    tradeTokenTypeIndex === Token.USDC, setIsBridgeInfoOpen: setIsBridgeInfoOpen }) }))] }) }), _jsx("div", { className: "text-2xl h-4 relative z-50 flex justify-center items-center", children: _jsx("button", { onClick: toggleClick, className: "replace-btn w-10 h-10 flex justify-center items-center rounded-full absolute p-2 input-items dark:hover:bg-custom-dark", "aria-label": 'Swap "from" and "to" networks and amounts', disabled: isApproved, "aria-disabled": isApproved, children: _jsx("img", { className: "mx-auto my-0", src: isDarkMode ? arrowWhite : arrow, alt: "Down Arrow", style: {
                                            width: '1.3125rem',
                                            height: '1.25rem',
                                        } }) }) }), _jsxs(Frame, { className: "frame-bottom z-30 dark:border-custom-black dark:md:bg-custom-light", title: "To", header: _jsx("div", { className: "flex flex-row justify-end mb-2 items-center", "aria-label": "balance", children: _jsxs("p", { className: "mr-2 font-normal", children: ["Balance :", ' ', isFetchingBalances && isConnected ? (_jsx(Loading, {})) : selectedToNetwork &&
                                                balances[selectedToNetwork.networkID] ? (formatNumberWithFourDecimalPlaces(balances[selectedToNetwork.networkID]?.formatted)) : ('0')] }) }), children: [_jsx("button", { className: "flex items-center justify-between h-12 w-56 border border-black dark:border-custom-black  rounded-full pr-1 pl-2 dark:bg-custom-dark", onClick: () => {
                                            clearInterval(calcTimerID);
                                            setIsFirstFeeCalculated(false);
                                            setNetworkFlag('to');
                                            setIsOpenNetworkModal(true);
                                        }, disabled: isApproved || isProcessing || (!isInitialized && isConnected), "aria-label": "Select a to network", "aria-disabled": isApproved || isProcessing || (!isInitialized && isConnected), children: !isInitialized && isConnected ? (_jsx("div", { className: "w-full flex justify-center items-center", children: _jsx(Loading, {}) })) : (_jsxs(_Fragment, { children: [_jsxs("div", { children: [selectedToNetwork?.img && (_jsx("img", { className: `h-6 inline min-w-6 w-auto ${switching ? 'blinking' : ''}`, src: getImage(selectedToNetwork.img), alt: selectedToNetwork.label, style: {
                                                                width: `${selectedToNetwork.width}rem`,
                                                                height: `${selectedToNetwork.height}rem`,
                                                            } })), _jsx("span", { className: `ml-2 ${switching ? 'blinking' : ''}`, children: selectedToNetwork?.label }), selectedToNetwork.id === 0 &&
                                                            isValidated &&
                                                            validateErrorMsg ===
                                                                constants.INVALID_NETWORK_SELECTION_ERROR && (_jsx(StyledTippy, { placement: "bottom", animation: "fade", visible: true, arrow: true, content: _jsx("div", { className: "tooltip", children: _jsx("div", { children: constants.NOT_SELECT_NETWORK }) }), offset: [0, 20], zIndex: 30, children: _jsx("span", { className: "error-indicator" }) }))] }), _jsx("div", { className: "arrow", children: _jsx("img", { src: isDarkMode ? triangleWhite : triangle, style: {
                                                            width: '0.75rem',
                                                            height: '0.5625rem',
                                                        }, alt: "Open Select" }) })] })) }), _jsx("input", { className: "focus-outline input border-b-0 w-40", type: "number", name: "estimatedReceived", id: "estimatedReceived", disabled: true, value: estimatedReceived, max: 0.1, "aria-label": 'Estimated amount of "to" currency' }), _jsx("span", { className: "py-1 pl-3 font-bold text-sm leading-[1.75]", children: toCurrency })] }), _jsxs("div", { className: "z-0 flex flex-col", children: [_jsxs("button", { className: "float-right my-2 mr-3 ml-auto input-items", onClick: () => setIsOpen(!isOpen), "aria-label": "Show options", children: ["Options", _jsx("img", { src: isDarkMode ? lineTriangleWhite : lineTriangle, alt: "Triangle Icon", className: `ml-1 inline-block transition duration-200 ease-out-quart w-[9px] h-[12px] ${isOpen ? 'rotate-180' : 'rotate-90'}`, style: {
                                                    width: '1rem',
                                                    height: '1rem',
                                                } })] }), _jsx(Transition, { in: isOpen, timeout: 300, appear: true, unmountOnExit: true, children: (state) => (_jsx("div", { style: {
                                                ...transitionStyles[state],
                                                transition: 'opacity 300ms ease-in-out',
                                            }, children: _jsx(DetailRowWithIcon, { className: "frame-address border-none max-w-full relative items-center z-10", icon: addressIcon, iconAlt: "Address", tooltipContentIcon: zzz, left: _jsx("label", { htmlFor: "address", children: _jsx("span", { children: "Receiving address" }) }), right: _jsx("input", { className: "focus-outline input-address w-full", type: "text", id: "address", placeholder: "Enter address (e.g.0x123...)", value: recipient, onChange: (e) => {
                                                        setRecipient(e.target.value);
                                                    }, disabled: isApproved || isUpwardTrade, "aria-disabled": isApproved || isUpwardTrade, autoComplete: "off" }), tooltipContent: _jsx("p", { className: "typo text-justify leading-[1.2rem] p-4", children: constants.ADDRESS_EXPLANATION }) }) })) })] }), _jsx("div", { className: "w-full my-4 md:mb-3 md:mt-0 text-sm px-6 z-0", children: _jsx(DetailRowWithIcon, { title: "Fees", icon: feeOld, 
                                    //icon={isDarkMode ? feeWhite : fee}
                                    iconAlt: "Fees", tooltipContentIcon: jito, right: isFeeCalculated && (_jsxs("span", { children: [totalFee, " ", unit] })), tooltipContent: _jsxs("div", { className: "p-4", children: [_jsx(TextRow, { leftText: "Total", rightText: `${totalFee} ${unit}` }), _jsx(TextRow, { leftText: "Relayer fee", rightText: `${totalFee} ${unit}` }), _jsx(TextRow, { leftText: "Protocol fee", rightText: `0.00 ${unit}`, className: "mb-2" }), _jsx("p", { className: "typo text-justify leading-[1.2rem]", children: constants.FEE_EXPLANATION })] }) }) })] }), _jsx("div", { className: "w-full flex flex-row z-0", children: _jsx(Footer, { isApproved: isApproved, isFirstFeeCalculated: isFirstFeeCalculated, txhash: txhash, prepare: prepare, approve: approve, executeTrade: executeTrade, executeTradeTemporarily: executeTradeTemporarily, isInitialized: isInitialized, isTemporarySetting: isTemporarySetting, setAmount: setAmount, setPlaceholder: setPlaceholder, isCctp: isCctp }) })] }), isProcessing && !isProcessingModalOpen && (_jsx(MessageMobile, { className: "z-0 hidden lg:block" })), isCctpProcessingModalOpen && (_jsx(MessageMobile, { className: "z-0 hidden lg:block" })), _jsx(Teleport, { children: _jsx(SimpleModal, { title: "Choose Network", onClose: () => {
                        setIsOpenNetworkModal(false);
                    }, isOpen: isOpenNetworkModal, children: _jsx(_Fragment, { children: _jsx(NetworkModalContent, { setIsOpenNetworkModal: setIsOpenNetworkModal, networkFlag: networkFlag, selectFromNetwork: selectFromNetwork, selectedToNetworkHandler: selectedToNetworkHandler, setIsFocusOut: setIsFocusOut }) }) }) })] }));
});
export default MainCard;
