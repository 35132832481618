import { useEffect, useState } from 'react';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { NETWORKS } from '@/constants';
import { useSelectedNetworkContext } from '@/context';
import { 
//getAllNetworkContractInfo,
PheasantNetworkBridgeChild, Web3ServiceBrowserNew as Web3Service, BondManager, PheasantNetworkParameters, 
//getNetworkCodeByChainId,
Token, Util, FrontNetworkProvider, NetworkManager, } from '@pheasant-network/pheasant-sdk';
import { getSelectableNetwork } from '@/utils';
let web3;
export const useInstance = () => {
    const { chain: currentChain } = useNetwork();
    const { isError: isSwitchError } = useSwitchNetwork();
    const { address, isConnected } = useAccount();
    const [thresholds, setThresholds] = useState({});
    const [usdcThresholds, setUsdcThresholds] = useState({});
    const [recipient, setRecipient] = useState('');
    const [isInitialized, setIsInitialized] = useState(false);
    const [bridge_contract_for_call, setBridge_contract_for_call] = useState(null);
    const { selectedFromNetwork, selectedToNetwork, setSelectedNetwork } = useSelectedNetworkContext();
    const [allContractAddressList, setAllContractAddressList] = useState({});
    const [archiveAllContractAddressList, setArchiveAllContractAddressList] = useState({});
    const [isTemporarySetting, setIsTemporarySetting] = useState(false);
    const initialAllForCalls = NETWORKS.reduce((acc, network) => {
        if (network.id !== 0) {
            acc[network.id] = {};
        }
        return acc;
    }, {});
    const [allForCalls, setAllForCalls] = useState(initialAllForCalls);
    useEffect(() => {
        if (isConnected) {
            initialize();
        }
    }, [isConnected]);
    // When network is switched, initialize all
    useEffect(() => {
        if (currentChain) {
            setSelectedNetwork(!currentChain.unsupported && currentChain?.id
                ? getSelectableNetwork(currentChain.id)
                : NETWORKS[0]);
        }
    }, [currentChain, isSwitchError, setSelectedNetwork]);
    const initialize = async () => {
        let ethereumEndpoint = process.env.REACT_APP_MAINNET_ENDPOINT;
        web3 = await Web3Service.init(ethereumEndpoint);
        setTemporarySetting();
        await initializeAll();
    };
    const initializeAll = async () => {
        try {
            console.log('initializeAll');
            setIsInitialized(false);
            await initializeCallEndpoint();
            console.log('Finish the initialization');
            setRecipient(address);
            setIsInitialized(true);
            //setDisputableList(Array(Object.values(tradeList).length).fill(true)) //initialize
        }
        catch (error) {
            console.log(error);
        }
    };
    // unused
    // const initializeSepolia = async () => {
    //   web3_mainnet = await Web3Service.init(
    //     process.env.REACT_APP_SEPOLIA_ENDPOINT,
    //     undefined,
    //   )
    //   setWeb3Mainnet(web3_mainnet)
    // }
    const initializeMainnet = async () => {
        let ethereumEndpoint;
        ethereumEndpoint = process.env.REACT_APP_MAINNET_ENDPOINT;
        const web3_mainnet = await Web3Service.init(ethereumEndpoint);
        allForCalls[1]['web3'] = web3_mainnet;
    };
    const initializeCallEndpoint = async () => {
        const polygonNetworkId = 137;
        const optimismNetworkId = 10;
        const arbitrumNetworkId = 42161;
        const scrollNetworkId = 534352;
        const zkSyncNetworkId = 324;
        const baseNetworkId = 8453;
        const polygonZkEvmNetworkId = 1101;
        const lineaNetworkId = 59144;
        const taikoNetworkId = 167000;
        const mantleNetworkId = 5000;
        const modeNetworkId = 34443;
        const xLayerNetworkId = 196;
        const polygonEndpoint = process.env.REACT_APP_POLYGON_ENDPOINT;
        const optimismEndpoint = process.env.REACT_APP_OPTIMISM_ENDPOINT;
        const arbitrumEndpoint = process.env.REACT_APP_ARBITRUM_ENDPOINT;
        const scrollEndpoint = process.env.REACT_APP_SCROLL_ENDPOINT;
        const zkSyncEndpoint = process.env.REACT_APP_ZK_SYNC_ENDPOINT;
        const baseEndpoint = process.env.REACT_APP_BASE_ENDPOINT;
        const polygonZkEvmEndpoint = process.env.REACT_APP_POLYGON_ZK_EVM_ENDPOINT;
        const lineaEndpoint = process.env.REACT_APP_LINEA_ENDPOINT;
        const taikoEndpoint = process.env.REACT_APP_TAIKO_ENDPOINT;
        const mantleEndpoint = process.env.REACT_APP_MANTLE_ENDPOINT;
        const modeEndpoint = process.env.REACT_APP_MODE_ENDPOINT;
        const xLayerEndpoint = process.env.REACT_APP_XLAYER_ENDPOINT;
        const ethereumProcedures = async () => {
            await initializeMainnet();
        };
        const networkProvider = await FrontNetworkProvider.init(process.env.REACT_APP_ENV);
        const networkManager = await NetworkManager.init(networkProvider);
        archiveAllContractAddressList['v1'] =
            await getArchiveAllContractAddressList('v1');
        const polygonProcedures = async () => {
            try {
                const web3_polygon = await Web3Service.init(polygonEndpoint);
                const id = 3;
                const networkId = polygonNetworkId;
                allForCalls[3]['web3'] = web3_polygon;
                allContractAddressList['polygon'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                const polygon_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_polygon, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[3]['bridgeContract'] = polygon_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_polygon, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[3]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_polygon, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[3]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const optimismProcedures = async () => {
            try {
                const web3_optimism = await Web3Service.init(optimismEndpoint);
                const id = 2;
                const networkId = optimismNetworkId;
                allContractAddressList['optimism'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_optimism;
                const optimism_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_optimism, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = optimism_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_optimism, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_optimism, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const arbitrumProcedures = async () => {
            try {
                const web3_arbitrum = await Web3Service.init(arbitrumEndpoint);
                const id = 4;
                const networkId = arbitrumNetworkId;
                allContractAddressList['arbitrum'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_arbitrum;
                const arbitrum_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_arbitrum, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = arbitrum_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_arbitrum, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_arbitrum, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const scrollProcedures = async () => {
            try {
                const web3_scroll = await Web3Service.init(scrollEndpoint);
                const id = 5;
                const networkId = scrollNetworkId;
                allContractAddressList['scroll'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_scroll;
                const scroll_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_scroll, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = scroll_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_scroll, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_scroll, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const zkSyncProcedures = async () => {
            try {
                const web3_zkSync = await Web3Service.init(zkSyncEndpoint);
                const id = 6;
                const networkId = zkSyncNetworkId;
                allContractAddressList['zkSync'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_zkSync;
                const zkSync_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_zkSync, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = zkSync_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_zkSync, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_zkSync, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const baseProcedures = async () => {
            try {
                const web3_base = await Web3Service.init(baseEndpoint);
                const id = 7;
                const networkId = baseNetworkId;
                allContractAddressList['base'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_base;
                const base_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_base, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = base_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_base, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_base, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const polygonZkEvmProcedures = async () => {
            try {
                const web3_polygonZkEvm = await Web3Service.init(polygonZkEvmEndpoint);
                const id = 8;
                const networkId = polygonZkEvmNetworkId;
                allContractAddressList['polygonZkEvm'] =
                    await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_polygonZkEvm;
                const polygonZkEvm_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_polygonZkEvm, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = polygonZkEvm_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_polygonZkEvm, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_polygonZkEvm, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const lineaProcedures = async () => {
            try {
                const web3_linea = await Web3Service.init(lineaEndpoint);
                const id = 9;
                const networkId = lineaNetworkId;
                allContractAddressList['linea'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_linea;
                const linea_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_linea, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = linea_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_linea, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_linea, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const taikoProcedures = async () => {
            try {
                const web3_taiko = await Web3Service.init(taikoEndpoint);
                const id = 10;
                const networkId = taikoNetworkId;
                allContractAddressList['taiko'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_taiko;
                const taiko_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_taiko, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = taiko_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_taiko, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_taiko, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const mantleProcedures = async () => {
            try {
                const web3_mantle = await Web3Service.init(mantleEndpoint);
                const id = 12;
                const networkId = mantleNetworkId;
                allContractAddressList['mantle'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_mantle;
                const mantle_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_mantle, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = mantle_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_mantle, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_mantle, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const modeProcedures = async () => {
            try {
                const web3_mode = await Web3Service.init(modeEndpoint);
                const networkId = modeNetworkId;
                const id = 13;
                allContractAddressList['mode'] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3_mode;
                const mantle_bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3_mode, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = mantle_bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3_mode, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3_mode, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        const xLayerProcedures = async () => {
            try {
                const web3 = await Web3Service.init(xLayerEndpoint);
                const networkId = xLayerNetworkId;
                const id = 14;
                const name = 'xLayer';
                allContractAddressList[name] = await getAllContractAddressList(networkId, 'pheasantNetworkBridgeAddress');
                allForCalls[id]['web3'] = web3;
                const bridge_contract_for_call = await PheasantNetworkBridgeChild.init(web3, await getLatestContractAddress(networkId, 'pheasantNetworkBridgeAddress'), networkManager, undefined);
                allForCalls[id]['bridgeContract'] = bridge_contract_for_call;
                const bond_manager_for_call = await BondManager.init(web3, await getLatestContractAddress(networkId, 'bondManagerAddress'), networkManager, undefined);
                allForCalls[id]['bondManager'] = bond_manager_for_call;
                const parameter_for_call = await PheasantNetworkParameters.init(web3, await getLatestContractAddress(networkId, 'parametersAddress'), networkManager, undefined);
                allForCalls[id]['parameter'] = parameter_for_call;
                let threshold = {};
                let usdcThreshold = {};
                threshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.ETH);
                setThresholds(Object.assign(thresholds, {
                    [id]: threshold,
                }));
                usdcThreshold = await getThresholds(parameter_for_call, networkManager.getNetworkCodeByChainId(networkId), Token.USDC);
                setUsdcThresholds(Object.assign(usdcThresholds, {
                    [id]: usdcThreshold,
                }));
            }
            catch (e) {
                console.log(e);
            }
        };
        let procedureList = [];
        procedureList = [
            ethereumProcedures(),
            polygonProcedures(),
            optimismProcedures(),
            lineaProcedures(),
            arbitrumProcedures(),
            baseProcedures(),
            polygonZkEvmProcedures(),
            //mantleProcedures(),
            zkSyncProcedures(),
            scrollProcedures(),
            modeProcedures(),
            xLayerProcedures(),
            taikoProcedures(),
        ];
        await Promise.all(procedureList);
    };
    // const getThresholdsOld = async (bridgeContractForCall: any) => {
    //   const threshold = await bridgeContractForCall.getTradeThreshold()
    //   const minimumAmount = await bridgeContractForCall.getTradeMinimumAmount()
    //   THRESHOLD_ETH_AMOUNT = parseFloat(await fromWei(threshold))
    //   MINIMUM_ETH_AMOUNT = parseFloat(await fromWei(minimumAmount))
    //   return {
    //     thresholdETHAmount: THRESHOLD_ETH_AMOUNT,
    //     minimumETHAmount: MINIMUM_ETH_AMOUNT,
    //   }
    // }
    const getThresholds = async (parameterForCall, networkCode, token) => {
        const threshold = await parameterForCall.tradeThreshold(networkCode, token);
        const minimumAmount = await parameterForCall.tradeMinimumAmount(networkCode, token);
        return {
            thresholdAmount: token === Token.ETH ? parseFloat(await Util.fromWei(threshold)) : parseFloat(await Util.fromUSDC(threshold)),
            minimumAmount: token === Token.ETH ? parseFloat(await Util.fromWei(minimumAmount)) : parseFloat(await Util.fromUSDC(minimumAmount))
        };
    };
    const setTemporarySetting = () => {
        if (selectedFromNetwork.id == 9 || selectedToNetwork.id == 9) {
            setIsTemporarySetting(false);
        }
        else {
            setIsTemporarySetting(true);
        }
    };
    const getArchiveAllContractAddressList = async (version) => {
        const networkProvider = await FrontNetworkProvider.init(version);
        const networkManager = await NetworkManager.init(networkProvider);
        return (await networkManager.getAllNetworkContractInfo('all'));
    };
    const getAllContractAddressList = async (networkId, token) => {
        const networkProvider = await FrontNetworkProvider.init(process.env.REACT_APP_ENV);
        const networkManager = await NetworkManager.init(networkProvider);
        const tokenAddressList = await networkManager.getAllNetworkContractInfo('all');
        return tokenAddressList[networkId][token];
    };
    const getLatestContractAddress = async (networkId, token) => {
        const tokenAddressList = await getAllContractAddressList(networkId, token);
        if (!Array.isArray(tokenAddressList)) {
            return tokenAddressList;
        }
        return tokenAddressList[tokenAddressList.length - 1];
    };
    return {
        web3,
        bridge_contract_for_call,
        getLatestContractAddress,
        setTemporarySetting,
        isTemporarySetting,
        isInitialized,
        thresholds,
        usdcThresholds,
        allForCalls,
        recipient,
        setRecipient,
        allContractAddressList,
        archiveAllContractAddressList,
    };
};
